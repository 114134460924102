import { NavLink } from 'react-router-dom'

import { ROUTES } from 'shared/config/routes'
import { getCurrentDate } from 'shared/utils/functions'
import { Button } from 'shared/ui'
import GiftIcon from 'shared/assets/icons/GiftIcon.webp'
import InvestImg from 'shared/assets/images/contacts/InvestImg.webp'

import { giftData } from '../model/gift'

import cls from './GiftPage.module.scss'

export const GiftPage = () => {
  const currentDate = getCurrentDate()

  return (
    <section className={cls.page}>
      <div className={cls.container}>
        <div className={cls.content}>
          <div className={cls.content_container}>
            <div className={cls.gift_desc}>
              <div className={cls.gift_desc__block1}>
                <p>Спасибо за ответы!</p>
              </div>

              <div className={cls.gift_desc__block2}>
                <p>ВАШ ПОДАРОК</p>
                <p>{giftData.giveSum}</p>
              </div>

              <div className={cls.gift_image}>
                <img src={InvestImg} alt="InvestImg" />
              </div>
            </div>

            <div className={cls.gift_desc__block3}>
              <p>Получите пакет акций при открытии счета</p>
              <p>
                Сделайте первое пополнение до {currentDate || '28.09.2023'},
                чтобы получить бонус!
              </p>
            </div>
          </div>

          <NavLink to={ROUTES.CONTACTS}>
            <Button iconPath={GiftIcon}>Получить</Button>
          </NavLink>
        </div>
      </div>
    </section>
  )
}
