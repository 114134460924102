import { useEffect } from 'react'

import useAppContext from 'app/store/useAppContext'
import { CookiesService } from 'shared/services/Cookies.service'

export const useReactPixelMetric = (isUse: boolean) => {
  const {
    store: {
      user: {
        searchParams: { metric },
      },
    },
  } = useAppContext()

  const cookieMetric = CookiesService.get({ name: 'metric' })

  useEffect(() => {
    if (metric && !cookieMetric) {
      CookiesService.set({ name: 'metric', token: metric})
    }
  }, [metric, cookieMetric])

  useEffect(() => {
    if (isUse && cookieMetric) {
      const ReactPixel = require('react-facebook-pixel')
      ReactPixel.default.init(cookieMetric.toString())
      ReactPixel.default.track('Lead')
    }
  }, [isUse, cookieMetric])
}
