import { Question } from '../types/questions'

import Image1 from 'shared/assets/images/questions/Image1.webp'
import Image2 from 'shared/assets/images/questions/Image2.webp'
import Image3 from 'shared/assets/images/questions/Image3.webp'
import Image4 from 'shared/assets/images/questions/Image4.webp'
import Image5 from 'shared/assets/images/questions/Image5.webp'
import Image6 from 'shared/assets/images/questions/Image6.webp'
import Image7 from 'shared/assets/images/questions/Image7.webp'
import Image8 from 'shared/assets/images/questions/Image8.webp'
import Image9 from 'shared/assets/images/questions/Image9.webp'
import Image10 from 'shared/assets/images/questions/Image10.webp'

export const managerName: string = 'Солиха'
export const managerCompleteAnswers = [
  {
    id: 1,
    text: 'Спасибо за Ваши ответы! Подождите пару секунд, чтобы я могла найти для Вас самое лучшее предложение!',
  },
  {
    id: 2,
    text: 'Анализ ответов: 100%',
  },
  {
    id: 3,
    text: 'Выбор продукта: 100%',
  },
  {
    id: 4,
    text: 'Настройка стратегии: 100%',
  },
  {
    id: 5,
    text:
      'Отлично! Я нашла подходящий продукт!\n' +
      '\n' +
      'Оставьте заявку на открытие счета и заберите Ваш подарок!\n' +
      '\n' +
      'Нажмите “забрать” чтобы получить подарок!',
  },
]

export const questionsItems: Question[] = [
  {
    id: 1,
    text: 'Был ли у Вас опыт в инвестировании?',
    chatText:
      'Привет! Я виртуальный помощник USM Gold, созданный для помощи начинающим инвесторам.',
    options: ['Нет опыта', 'Меньше года', 'Больше года', 'Я опытный инвестор'],
    complete: 10,
    img: Image1,
  },
  {
    id: 2,
    text: 'Какова цель Ваших инвестиций?',
    chatText:
      'Отлично! \n' +
      '\n' +
      '\t Инвестиции в золото не требуют от Вас много знаний и навыков. \n' +
      '\n' +
      '\t Вам достаточно следовать нашим инструкциям, чтобы настроить программу.',
    options: [
      'Дополнительный доход',
      'Основной доход',
      'Хобби',
      'Изучения сферы инвестиций',
    ],
    complete: 20,
    img: Image2,
  },
  {
    id: 3,
    text: 'Сколько времени Вы планируете уделять инвестированию?',
    chatText:
      'Помните: инвестиции в золото - это стабильный источник как основного, так и дополнительного дохода.',
    options: [
      'Несколько часов в неделю',
      'До часа в день',
      ' 2-3 часа в день',
      '3-8 часов в день',
    ],
    complete: 30,
    img: Image3,
  },
  {
    id: 4,
    text: 'Какие рынки инвестирования Вам знакомы?',
    chatText:
      'Чтобы получать доход с продажи золота, достаточно просто следить за автоторговлей пару раз в день и фиксировать прибыль!',
    options: [
      'Фондовый рынок',
      'Криптовалютный рынок',
      'Рынок валют',
      'Товарно-сырьевой рынок',
    ],
    complete: 40,
    img: Image4,
  },
  {
    id: 5,
    text: 'Какую сумму Вы планируете инвестировать?',
    chatText:
      'Активы компании USM Gold распределены по многим отраслям рынка - в том числе и фондовая биржа.',
    options: [
      'От 100$ до 1000$',
      'От 1000$ до 3000$',
      'От 3000$ до 10000$',
      'Свыше 10000$',
    ],
    complete: 50,
    img: Image5,
  },
  {
    id: 6,
    text: 'Какой уровень дохода Вы ожидаете от инвестиций?',
    chatText:
      'Инвестируя от 100$ хотя бы раз, Вы сможете получать пассивный доход каждый месяц. ',
    options: [
      '5%-7% в месяц',
      '7%-10% в месяц',
      '10%-15% в месяц',
      'Более 15% в месяц',
    ],
    complete: 60,
    img: Image6,
  },
  {
    id: 7,
    text: 'Ваш возраст:',
    chatText:
      'Помните: чем больше ваша сумма инвестирования, тем выше процент вашей ставки по прибыли!',
    options: [
      'От 18 до 30 лет',
      'От 30 до 45 лет',
      'От 45 до 60 лет',
      'Старше 60 лет',
    ],
    complete: 70,
    img: Image7,
  },
  {
    id: 8,
    text: 'Какое образование Вы имеете?',
    chatText:
      'Помните: заключение договора и открытие инвест-счета возможно только для лиц старше 18-ти лет!',
    options: [
      'Высшее гуманитарное',
      'Высшее техническое',
      'Экономическое образование',
      'Не имею высшего образования',
    ],
    complete: 80,
    img: Image8,
  },
  {
    id: 9,
    text: 'Готовы ли рисковать при инвестировании?',
    chatText:
      'Чтобы инвестировать в акции, вам не нужно экономическое образование - доступ к платформе имеет каждый житель Узбекистана!',
    options: [
      'Да, минимально',
      'Да, максимально',
      'Нет',
      'Зависит от ситуации',
    ],
    complete: 90,
    img: Image9,
  },
  {
    id: 10,
    text: 'Какой метод обучения более комфортный для Вас?',
    chatText:
      'Инвестиции в золотодобычу - это наиболее безопасный формат инвестирования, который обеспечивает вам прибыль без рисков потери средств.',
    options: [
      'Обучающие материалы для чтения',
      'Обучающие видео материалы',
      'Сопровождение с учителем',
      'Все варианты',
    ],
    complete: 100,
    img: Image10,
  },
]
