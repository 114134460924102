import { Outlet, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { useSetUserApiData, useSetUserSearchParams } from 'entities/user'
import { useIsMobileModal, useSetIsMobileModal } from 'features/modal'
import { Header } from 'widgets/Header'
import { HeaderCaptcha } from 'widgets/HeaderCaptcha'
import { ROUTES } from 'shared/config/routes'

import cls from './MainLayout.module.scss'

export const MainLayout = () => {
  const { isMobileModal, isMobile500, isSomeModalsOpened } = useIsMobileModal()

  const { pathname } = useLocation()
  const isHomePage = pathname === ROUTES.MAIN
  const isQuestionsPage = pathname?.includes(ROUTES.QUESTIONS)
  const isChatPage = pathname === ROUTES.CHAT
  const isGiftPage = pathname === ROUTES.GIFT
  const isContactsPage = pathname?.includes(ROUTES.CONTACTS)
  const isCaptchaPage = pathname?.includes(ROUTES.CAPTCHA)

  useSetIsMobileModal()
  useSetUserSearchParams()
  useSetUserApiData()

  return (
    <div className="app">
      {isCaptchaPage ? <HeaderCaptcha /> : <Header />}
      <main
        className={classNames(
          'main',
          isMobileModal && isSomeModalsOpened ? 'main--fixed' : null,
          isHomePage && cls.main_home,
          (isHomePage || isGiftPage || isContactsPage) &&
            isMobile500 &&
            cls.main_home_start,
          isQuestionsPage && cls.main_questions,
          isChatPage && cls.main_chat,
          isContactsPage && cls.main_contacts,
          isCaptchaPage && cls.main_captcha,
          isMobile500 &&
            !isQuestionsPage &&
            !isChatPage &&
            !isCaptchaPage &&
            cls.main_no_bg,
        )}
      >
        <Outlet />
      </main>
    </div>
  )
}
