import cl from 'classnames'

import useAppContext from 'app/store/useAppContext'
import { Logo } from 'shared/ui'

import cls from './HeaderCaptcha.module.scss'

export const HeaderCaptcha = () => {
  const {
    store: { isMobileSmall },
  } = useAppContext()

  return (
    <header className={cl(cls.header)}>
      <div className={cls.layout}>
        <div
          className={cl(
            'container__desktop',
            'flex__row_center',
            cls.container,
          )}
        >
          <Logo isCaptcha isMobileSmall={isMobileSmall} />
        </div>
      </div>
    </header>
  )
}
