export const ROUTES = {
  MAIN: '/',
  QUESTIONS: '/questions',
  CHAT: '/chat',
  GIFT: '/gift',
  CONTACTS: '/contacts',
  REGISTERED: '/registered',
  CAPTCHA: '/captcha',
}

export type RoutesType = (typeof ROUTES)[keyof typeof ROUTES]
