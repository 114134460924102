import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useAppContext from 'app/store/useAppContext'
import { setUserQuestions } from 'app/store/actions'
import { ROUTES } from 'shared/config/routes'
import ManagerAvatarImg from 'shared/assets/images/chat/ManagerAvatarImg.webp'
import UserAvatarImg from 'shared/assets/images/chat/UserAvatarImg.webp'

import type { ChatItem } from '../../model/types/questions'
import {
  questionsItems,
  managerName,
  managerCompleteAnswers,
} from '../../model/schema/schema'

import cls from './Chat.module.scss'

const userMsgTimer = 1000
const botFirstMsgTimer = 1500
const botNextMsgTimer = 2500

export const Chat = () => {
  const navigate = useNavigate()

  const { dispatch } = useAppContext()

  const [messages, setMessages] = useState<ChatItem[]>([])
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [isTyping, setIsTyping] = useState<boolean>(false)
  const [isLastAnswer, setIsLastAnswer] = useState<boolean>(false)
  const [isShowGiftBtn, setIsShowGiftBtn] = useState<boolean>(false)

  const isLastQuestion = currentQuestionIndex === 9

  const messagesEndRef = useRef<HTMLDivElement>(null)

  const setCompleteBotMessage = () => {
    managerCompleteAnswers.forEach((answer, index) => {
      const isFirstMsg = index === 0
      const isOtherMsg =
        index !== 0 && index !== managerCompleteAnswers?.length - 1

      setTimeout(
        () => {
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: answer?.text, isFromUser: false, isFirstMsg, isOtherMsg },
          ])
          if (index === managerCompleteAnswers.length - 1) {
            setTimeout(() => {
              setIsShowGiftBtn(true)
            }, botFirstMsgTimer)
          }
        },
        (index + 1) * botFirstMsgTimer,
      )
    })
  }

  useEffect(() => {
    setIsTyping(true)

    setTimeout(() => {
      setMessages([
        {
          text: questionsItems[currentQuestionIndex].chatText,
          isFromUser: false,
          isFirstMsg: true,
        },
      ])
    }, botFirstMsgTimer)

    setTimeout(() => {
      setMessages([
        {
          text: questionsItems[currentQuestionIndex].chatText,
          isFromUser: false,
          isFirstMsg: true,
        },
        { text: questionsItems[currentQuestionIndex].text, isFromUser: false },
      ])

      setIsTyping(false)
    }, botNextMsgTimer)
  }, [])

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages, isShowGiftBtn])

  const handleOptionClick = (option: string) => {
    const nextIndex = currentQuestionIndex + 1

    if (option) {
      dispatch(
        setUserQuestions({
          [`q${nextIndex}`]: {
            question: questionsItems?.[currentQuestionIndex]?.text,
            answer: option,
          },
        }),
      )
    }

    if (nextIndex < questionsItems.length) {
      setCurrentQuestionIndex(nextIndex)

      setMessages((prevMessages) => [
        ...prevMessages,
        { text: option, isFromUser: true },
      ])

      setIsTyping(true)

      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            text: questionsItems[nextIndex].chatText,
            isFromUser: false,
            isFirstMsg: true,
          },
        ])
      }, userMsgTimer)

      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: questionsItems[nextIndex].text, isFromUser: false },
        ])

        setIsTyping(false)
      }, botNextMsgTimer)
    }

    if (isLastQuestion) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: option, isFromUser: true },
      ])

      setIsLastAnswer(true)

      setCompleteBotMessage()
    }
  }

  const onGiftBtnClick = () => {
    navigate(ROUTES.GIFT)
  }

  return (
    <div className={cls['chat-container']}>
      <div className={cls['chat-messages']}>
        {messages.map((message, index) => {
          const isBotFirstMsg =
            (message?.isFirstMsg || message?.isOtherMsg) &&
            !messages?.[index + 1]?.text

          const isBotPrevMsg =
            (message?.isFirstMsg || message?.isOtherMsg) &&
            messages?.[index + 1]?.text

          const isBotLastMsg =
            !message?.isFirstMsg &&
            !message?.isOtherMsg &&
            messages?.[index - 1]?.text

          return (
            <div key={index} className={cls['message-container']}>
              {message.isFromUser ? (
                <div className={cls['user-message']}>
                  <p className={cls['message-text']}>{message.text}</p>
                  <img src={UserAvatarImg} alt="User Avatar" />
                </div>
              ) : (
                <div className={cls['bot-message']}>
                  <>
                    {isBotFirstMsg && (
                      <img src={ManagerAvatarImg} alt="Manager Avatar" />
                    )}

                    {isBotPrevMsg && <span></span>}

                    {isBotLastMsg && (
                      <img src={ManagerAvatarImg} alt="Manager Avatar" />
                    )}
                  </>

                  <p className={cls['message-text']}>
                    {message?.isFirstMsg ? (
                      <>
                        <span className={cls['bot-name']}>{managerName}</span>
                        {message.text}
                      </>
                    ) : (
                      <>{message.text}</>
                    )}
                  </p>
                </div>
              )}
            </div>
          )
        })}

        <div ref={messagesEndRef} />
      </div>

      <div
        className={`${cls.options} ${isTyping ? cls['options-hidden'] : ''}`}
      >
        {currentQuestionIndex < questionsItems.length &&
          !isLastAnswer &&
          questionsItems[currentQuestionIndex].options.map((option, index) => (
            <>
              {!isTyping && (
                <button key={index} onClick={() => handleOptionClick(option)}>
                  {option}
                </button>
              )}
            </>
          ))}

        {isShowGiftBtn && (
          <button onClick={onGiftBtnClick}>Забрать подарок</button>
        )}
      </div>
    </div>
  )
}
