import React, { ButtonHTMLAttributes } from 'react'
import cl from 'classnames'

import cls from './Button.module.scss'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'default'
    | 'variant2'
    | 'variant3'
    | 'variant4'
    | 'wrapper'
    | 'captcha'
  className?: string
  iconPath?: string
  iconPosition?: 'left' | 'right'
}

export const Button = (props: ButtonProps) => {
  const {
    variant = 'default',
    iconPath,
    iconPosition = 'left',
    className,
    children,
    ...rest
  } = props

  return (
    <button
      {...rest}
      className={cl(
        cls.button,
        iconPath && cls.button_icon,
        cls[variant],
        className,
      )}
    >
      {iconPath && iconPosition === 'left' && (
        <div className={cls.icon}>
          <img src={iconPath} alt="icon-left" className={cls.icon__left} />
        </div>
      )}
      {children}
      {iconPath && iconPosition === 'right' && (
        <div className={cls.icon}>
          <img src={iconPath} alt="icon-right" className={cls.icon__right} />
        </div>
      )}
    </button>
  )
}
