import { useNavigate } from 'react-router-dom'

import useAppContext from 'app/store/useAppContext'
import type { IUserQuestions } from 'entities/user/model/user'
import { ROUTES } from 'shared/config/routes'
import type { QuestionsProps } from '../../model/types/questions'
import { QuestionsCheckboxes } from '../QuestionsCheckboxes/QuestionsCheckboxes'

import cls from './Questions.module.scss'

export const Questions = (props: QuestionsProps) => {
  const { page = 1, questions } = props

  const navigate = useNavigate()

  const {
    store: {
      user: { questions: questionsStore },
    },
    dispatch,
  } = useAppContext()

  const questionNumber = page ? page - 1 : 0
  const lastPageNumber = 10

  const onSelectAnswer = () => {
    if (page !== lastPageNumber) {
      setTimeout(() => {
        navigate(`${ROUTES.QUESTIONS}/${page + 1}`)
      }, 500)
    }

    if (page === lastPageNumber) {
      setTimeout(() => {
        navigate(ROUTES.CONTACTS)
      }, 500)
    }
  }

  const pageStr = `q${page}` as keyof IUserQuestions
  const questionImg = questions?.[questionNumber]?.img
  const options = questions?.[questionNumber]?.options
  const question = questions?.[questionNumber]?.text
  const answer = questionsStore?.[pageStr]?.answer ?? null

  return (
    <div className={cls.container}>
      <div className={cls.left_block}>
        <QuestionsCheckboxes
          questionNumber={page}
          question={question}
          answer={answer}
          options={options}
          dispatch={dispatch}
          onSelectAnswer={onSelectAnswer}
        />
      </div>
      <div className={cls.right_block}>
        <img src={questionImg} alt="questionImg" />
      </div>
    </div>
  )
}
