import { useLocation } from 'react-router-dom'
import cl from 'classnames'

import {
  setIsCookiesModal,
  setIsPrivacyPolicyModal,
  setModals,
} from 'app/store/actions'
import { useIsMobileModal } from 'features/modal'
import { SubmitAffirmationModal } from 'features/affirmation/SubmitAffirmationModal'
import { ROUTES } from 'shared/config/routes'
import { setAllObjValuesFalse } from 'shared/utils/functions'
import { Logo } from 'shared/ui'
import CloseWhiteIcon from 'shared/assets/icons/CloseWhiteIcon.png'

import { Layout } from '../../_Layout'
import { Menu } from './Menu/Menu'

import cls from './Header.module.scss'

export const Header = () => {
  const {
    isMobile,
    isMobileModal,
    isMobile500,
    modals,
    setIsMobileModal,
    dispatch,
  } = useIsMobileModal()

  const { pathname } = useLocation()

  const isChatPage = pathname === ROUTES.CHAT
  const isContactsPage = pathname === ROUTES.CONTACTS
  const isRegisteredPage = pathname === ROUTES.REGISTERED

  const isShowThanksHeader = isContactsPage && !isMobile500
  const isShowButton = !isMobile && !isRegisteredPage && !isContactsPage

  const closeAllModals = () => {
    const isAffirmationAndPolicyModalOpened =
      modals.isAffirmationModal &&
      (modals.isCookiesModal || modals.isPrivacyPolicyModal)

    if (isAffirmationAndPolicyModalOpened) {
      dispatch(setIsCookiesModal(false))
      dispatch(setIsPrivacyPolicyModal(false))
    } else {
      const objModalsFalseValues = setAllObjValuesFalse(modals)

      dispatch(setModals(objModalsFalseValues))

      setIsMobileModal(false)
    }
  }

  return (
    <header
      className={cl(
        cls.header,
        isChatPage && cls.header_chat,
        isShowThanksHeader && cls.header_contacts,
        isMobileModal ? cls['header--fixed'] : null,
      )}
    >
      <Layout className={cls.layout}>
        {!isShowThanksHeader && (
          <div className={cls.left_block}>
            {isMobileModal && (
              <img
                className={cls.header__left_icon}
                src={CloseWhiteIcon}
                alt="CloseWhiteIcon"
                onClick={closeAllModals}
              />
            )}
            <Logo isChat={isChatPage} />
            {!isMobile && !isRegisteredPage && <Menu />}
          </div>
        )}

        {isShowButton && (
          <div className={cls.right_block}>
            <SubmitAffirmationModal
              btnProps={{ variant: 'variant3' }}
              btnChildren="Оставить заявку"
            />
          </div>
        )}

        {isShowThanksHeader && (
          <div className={cls.contacts_block}>
            <p>Спасибо за ответы! Вам одобрен доступ к платформе!</p>
          </div>
        )}
      </Layout>
    </header>
  )
}
