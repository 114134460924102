import React, { ReactNode, useEffect, useState } from 'react'

import useAppContext from 'app/store/useAppContext'
import { setIsAffirmationModal } from 'app/store/actions'
import { AffirmationForm } from 'features/affirmation/AffirmationForm'
import { Button, ButtonProps, Modal } from 'shared/ui'
import LogoIcon from 'shared/assets/icons/LogoIcon.webp'
import CloseWhiteIcon from 'shared/assets/icons/CloseWhiteIcon.png'

import cls from './SubmitModal.module.scss'

interface ISubmitAffirmationProps {
  btnProps?: ButtonProps
  btnChildren?: ReactNode
}

const customSubmitModalStyles = {
  overlay: {
    background: 'var(--bg-opacity2)',
    padding: '0',
    margin: '0',
  },
  content: {
    width: '100%',
    maxWidth: '500px',
    maxHeight: 'calc(100vh - 67px)',
    overflow: 'auto',
    borderRadius: '8px',
    border: '2px solid #393939',
    boxShadow: '2px 2px 4px #00000040',
    padding: '0',
    margin: '0',
  },
}
const customMobileSmallModalStyles = {
  overlay: {
    backgroundColor: 'var(--bg-opacity2)',
  },
  content: {
    width: '100vw',
    height: `calc(100vh - 67px)`,
    maxHeight: '100vh',
    margin: '67px 0 0 0',
    overflow: 'auto',
  },
}

export function SubmitAffirmationModal(props: ISubmitAffirmationProps) {
  const { btnProps, btnChildren } = props

  const {
    store: {
      isMobile,
      isMobileSmall,
      modals: { isAffirmationModal, isSuccessAffirmationModal },
      user,
    },
    dispatch,
  } = useAppContext()

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const openModal = () => {
    dispatch(setIsAffirmationModal(true))
  }
  const closeModal = () => {
    dispatch(setIsAffirmationModal(false))
  }

  useEffect(() => {
    setModalIsOpen(isAffirmationModal)
  }, [isAffirmationModal])

  const isMobileModalOpen = isMobileSmall && isAffirmationModal
  const customStyles = isMobileModalOpen
    ? customMobileSmallModalStyles
    : customSubmitModalStyles

  return (
    <div>
      <Button {...btnProps} onClick={openModal}>
        {btnChildren}
      </Button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        isCloseIcon={false}
        content={
          <div>
            {!isMobileModalOpen && (
              <div className={cls.modal__header}>
                <div className={cls.header_logo}>
                  <img src={LogoIcon} alt="LogoIcon" />
                </div>
                <div className={cls.header_icon}>
                  <img
                    src={CloseWhiteIcon}
                    alt="CloseWhiteIcon"
                    onClick={closeModal}
                  />
                </div>
              </div>
            )}
            <AffirmationForm
              user={user}
              dispatch={dispatch}
              className={cls.modal__form}
              title="Оставьте Ваши контакты, чтобы открыть инвест-счет"
              isAffirmationModal={isAffirmationModal}
            />
          </div>
        }
      />
    </div>
  )
}
