import cls from './Menu.module.scss'

export const Menu = () => {
  return (
    <nav className={cls.menu}>
      <ul>
        <li>
          <a href="#ourAdvantages">О нас</a>
        </li>
        <li>
          <a href="#hovInvest">FAQ</a>
        </li>
        <li>
          <a href="#contacts">Контакты</a>
        </li>
      </ul>
    </nav>
  )
}
