import { Dispatch, useEffect, useState } from 'react'

import { setUserQuestions } from 'app/store/actions'
import cls from './QuestionsCheckboxes.module.scss'

interface QuestionsCheckboxesProps {
  question: string
  questionNumber: number
  answer?: string | null
  options: string[]
  dispatch: Dispatch<any>
  onSelectAnswer?: () => void
}

export const QuestionsCheckboxes = (props: QuestionsCheckboxesProps) => {
  const {
    question,
    questionNumber,
    answer,
    options,
    dispatch,
    onSelectAnswer,
  } = props

  const [selectedOption, setSelectedOption] = useState(answer)

  const handleOptionChange = (event: any) => {
    const value = event?.target?.value

    setSelectedOption(value)

    if (question && value) {
      dispatch(
        setUserQuestions({
          [`q${questionNumber}`]: {
            question,
            answer: value,
          },
        }),
      )

      onSelectAnswer?.()
    }
  }

  useEffect(() => {
    setSelectedOption(answer)
  }, [answer])

  return (
    <div className={cls.checkboxes}>
      {options?.map((option, index) => {
        const optionId = `option-${index}`

        return (
          <div key={index} className={cls.input_container}>
            <input
              type="radio"
              id={optionId}
              name="options"
              value={option ?? selectedOption}
              checked={selectedOption === option}
              onChange={handleOptionChange}
            />
            <label className={cls.input_container__label} htmlFor={optionId}>
              <span className={cls.input_container__text}>{option}</span>
            </label>
          </div>
        )
      })}
    </div>
  )
}
