import { BeforeMaskedStateChangeStates } from 'shared/lib/input-mask'

import { PAST_BUG_PHONE, PHONE_TITLE } from '../_model/constants'

export const phoneMaskChange = ({
                                  currentState,
                                  nextState,
                                }: BeforeMaskedStateChangeStates) => {
  if (currentState?.value.includes(PAST_BUG_PHONE)) {
    return {
      ...currentState,
      value: currentState.value.replace(PAST_BUG_PHONE, PHONE_TITLE),
    }
  }

  return nextState
}
