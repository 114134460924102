import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useAppContext from 'app/store/useAppContext'
import { ROUTES } from 'shared/config/routes'

export const useIsNoAnswers = () => {
  const navigate = useNavigate()

  const {
    store: { user: { questions} },
  } = useAppContext()

  useEffect(() => {
    if (!questions?.q1?.answer && !questions?.q10?.answer) {
      navigate(`${ROUTES.QUESTIONS}/1`)
    }
  }, [questions])
}