import cl from 'classnames'

import useAppContext from 'app/store/useAppContext'
import { CaptchaForm } from 'features/captcha'
import QuestionImg from 'shared/assets/images/captcha/QuestionImg.webp'
import QuestionMobileImg from 'shared/assets/images/captcha/QuestionMobileImg.webp'
import LogoImg from 'shared/assets/images/captcha/LogoImg.webp'

import cls from './CaptchaPage.module.scss'

export const CaptchaPage = () => {
  const {
    store: { isMobile500 },
    dispatch,
  } = useAppContext()

  return (
    <>
      <section className={cls.page}>
        <div className={cl('container__desktop', cls.page_container)}>
          <div className={cls.container}>
            {!isMobile500 && (
              <h4 className={cls.breadcrumb}>Проверка безопасности</h4>
            )}

            <div className={cls.content}>
              <div className={cls.left_block}>
                <img
                  src={isMobile500 ? QuestionMobileImg : QuestionImg}
                  alt="QuestionImg"
                />
              </div>
              <div className={cls.right_block}>
                <CaptchaForm
                  dispatch={dispatch}
                  logo={LogoImg}
                  isShowTitle={!isMobile500}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
