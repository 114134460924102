import React, { Dispatch, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import cl from 'classnames'

import type { IAction } from 'app/store/model/action-types'
import { ROUTES } from 'shared/config/routes'
import { Button } from 'shared/ui'
import type { FormCaptchaInputs } from '../model/types/formCaptchaInputs'
import { formCaptchaSchema } from '../model/schema/formCaptchaSchema'

import cls from './CaptchaForm.module.scss'
import { CookiesService } from 'shared/services/Cookies.service'

interface CaptchaFormProps {
  dispatch: Dispatch<IAction>
  title?: string
  className?: string
  logo?: string
  isShowTitle?: boolean
}

export function CaptchaForm(props: CaptchaFormProps) {
  const { className, logo, isShowTitle = true } = props

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isSubmitSuccessful },
  } = useForm<FormCaptchaInputs>({
    defaultValues: {
      answer: '',
    },
    resolver: yupResolver(formCaptchaSchema),
  })

  const errMessage = errors?.answer?.message

  const onSubmit = handleSubmit(async (value) => {
    const formattedValue = value?.answer?.trim()?.toLowerCase()

    const errMsg =
      'Вы ввели неправильный ответ, попробуйте написать название предмета на русском языке еще раз!'

    // answer variants
    const options = [
      'золото',
      'золато',
      'zoloto',
      'zolato',
      'soloto',
      'solato',
      'слиток',
      'слитак',
    ]

    const isValidWord = options.some((option) =>
      new RegExp(
        `(^|\\s)${option}(\\s|$)|(^|\\s)(золотой|золатой)\\s?слит[ао]к(\\s|$)`,
        'i',
      ).test(formattedValue),
    )

    if (!isValidWord) {
      setError('answer', {
        type: 'manual',
        message: errMsg,
      })
      return
    }

    CookiesService.set({ name: 'acceptedCaptcha', token: 'accepted' })
    navigate(ROUTES.MAIN)
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful, reset])

  return (
    <form
      // @ts-ignore
      onSubmit={handleSubmit(onSubmit)}
      className={cl(cls.form, className)}
    >
      {logo && <img className={cls.logo} src={logo} alt="LogoImg" />}

      {isShowTitle && (
        <h3 className={cls.title}>
          Для верификации введите ответ <span>на русском языке</span> в поле
          ввода. Вы можете использовать кириллицу или латиницу.
        </h3>
      )}

      <div className={cl(cls.input_block, errMessage && cls.input_block_error)}>
        <input
          {...register('answer')}
          id="answer"
          name="answer"
          placeholder="Введите ответ"
          type="text"
        />
        {errors?.answer && <p className={cls.errors}>{errMessage}</p>}
      </div>

      <Button type="submit" variant="captcha">
        Отправить
      </Button>
    </form>
  )
}
