import cl from 'classnames'

import CardIcon from 'shared/assets/icons/home/CardIcon.webp'
import ProtectIcon from 'shared/assets/icons/home/ProtectIcon.webp'
import MouseIcon from 'shared/assets/icons/home/MouseIcon.webp'

import cls from './CardInfo.module.scss'

interface CardInfoProps {
  classname?: string
}

export const CardInfo = (props: CardInfoProps) => {
  const { classname } = props

  return (
    <div className={cl(cls.card, classname)}>
      <div className={cls.card__block}>
        <img src={CardIcon} alt="CardIcon" />
        <p>Бесплатное открытие счета</p>
      </div>
      <div className={cls.card__block}>
        <img src={ProtectIcon} alt="ProtectIcon" />
        <p>Гарантия возврата депозита </p>
      </div>
      <div className={cls.card__block}>
        <img src={MouseIcon} alt="MouseIcon" />
        <p>Лицензированные услуги</p>
      </div>
    </div>
  )
}
