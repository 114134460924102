import cl from 'classnames'

import { SubmitAffirmationModal } from 'features/affirmation/SubmitAffirmationModal'

import cls from './GiftPromotion.module.scss'

interface GiftPromotionProps {
  className?: string
}

export const GiftPromotion = (props: GiftPromotionProps) => {
  const { className } = props

  return (
    <SubmitAffirmationModal
      btnProps={{ variant: 'wrapper' }}
      btnChildren={
        <div className={cl(cls.gift, className)}>
          <div className={cls.left_block}>
            <div className={cls.gift_sum}>
              <p>600 000 сум</p>
            </div>
            <div className={cls.gift_desc}>
              <p>К сумме первого депозита</p>
            </div>
          </div>
          <div className={cls.right_block}>
            <div className={cls.gift_invest}>
              <p>
                Подарок инвестору <span>USM GOLD</span>
                <span className={cls.button}>забрать подарок</span>
              </p>
            </div>
          </div>
        </div>
      }
    />
  )
}
