import cls from './ProgressBar.module.scss'

interface ProgressBarProps {
  value?: number
}

export const ProgressBar = (props: ProgressBarProps) => {
  const { value } = props

  const imageStyle = {
    width: `${value}%`,
  }

  return (
    <div className={cls.progress_bar}>
      <p className={cls.progress_bar__value}>Готово: {value} %</p>
      <div className={cls.progress_bar__container}>
        <div className={cls.progress_bar__percent} style={imageStyle}></div>
      </div>
    </div>
  )
}
