import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import { questionsItems, Questions } from 'features/questions'
import { ProgressBar } from 'shared/ui'

import cls from './QuestionsPage.module.scss'

export const QuestionsPage = () => {
  const { id } = useParams()
  const numberId = id ? Number(id) : 1

  const title = questionsItems?.[numberId - 1]?.text
  const value = questionsItems?.[numberId - 1]?.complete

  return (
    <section className={cls.page}>
      <div className={classNames('container__desktop', cls.container)}>
        <div className={cls.questions}>
          <ProgressBar value={value} />

          <h2 className={cls.questions__title}>{title}</h2>

          <div className={cls.questions__content}>
            <Questions page={numberId} questions={questionsItems} />
          </div>
        </div>
      </div>
    </section>
  )
}
