import { NavLink } from 'react-router-dom'
import cl from 'classnames'

import { ROUTES } from 'shared/config/routes'
import LogoIcon from 'shared/assets/icons/LogoIcon.webp'
import LogoChatIcon from 'shared/assets/icons/LogoChatIcon.webp'
import LogoHeaderImg from 'shared/assets/images/captcha/LogoImg.webp'
import LogoHeaderHorizontalImg from 'shared/assets/images/captcha/LogoHorizontalImg.webp'

import cls from './Logo.module.scss'

interface Logo {
  className?: string
  isChat?: boolean
  isCaptcha?: boolean
  isMobileSmall?: boolean
}

export const Logo = (props: Logo) => {
  const { className, isChat, isCaptcha, isMobileSmall } = props

  return isCaptcha ? (
    <div className={cls.logo}>
      <img
        src={isMobileSmall ? LogoHeaderHorizontalImg : LogoHeaderImg}
        alt="LogoHeaderImg"
      />
    </div>
  ) : (
    <NavLink
      to={ROUTES.MAIN}
      className={cl(cls.logo, isChat && cls.logo_chat, className)}
    >
      <img src={isChat ? LogoChatIcon : LogoIcon} alt="LogoIcon" />
    </NavLink>
  )
}
