import type { PropsWithChildren } from 'react'
import cl from 'classnames'

import cls from './Layout.module.scss'

interface LayoutProps extends PropsWithChildren {
  className?: string
}

export const Layout = ({ children, className }: LayoutProps) => {
  return (
    <div className={`${cls.layout} ${className ?? ''}`}>
      <div
        className={cl(
          'container__desktop_outside',
          'flex__row_center',
          cls.container,
        )}
      >
        {children}
      </div>
    </div>
  )
}
